import { NotifectionTypes } from "./constant";

const Initial_State = {
    data: [],
    loading: false,
    message: ''
};


// const addRatingReducer = (state = Initial_State, action) => {
//     switch (action.type) {
//         case RatingTypes.ADD_RATING_LOADING:
//             return {
//                 data: state.data,
//                 loading: true,
//             };
//         case RatingTypes.ADD_RATING_SUCCESS:
//             return {
//                 data: action.payload,
//                 loading: false,
//             };
//         case RatingTypes.ADD_RATING_ERROR:
//             return {
//                 data: action.payload,
//                 loading: false,
//             };
//         case RatingTypes.ADD_RATING_RESET:
//             return Initial_State;
//         default:
//             return state;
//     }
// };

const getNotifectionReducer = (state = Initial_State, action) => {
    switch (action.type) {
        case NotifectionTypes.GET_NOTIFECTION_LOADING:
            return {
                data: state.data,
                loading: true,
            };
        case NotifectionTypes.GET_NOTIFECTION_SUCCESS:
            return {
                data: action.payload,
                loading: false,
            };
        case NotifectionTypes.GET_NOTIFECTION_ERROR:
            return {
                data: action.payload,
                loading: false,
            };
        // case NotifectionTypes.GET_NOTIFECTION_RESET:
        //     return Initial_State;
        default:
            return state;
    }
};

const getNotifectionQountReducer = (state = Initial_State, action) => {
    switch (action.type) {
        case NotifectionTypes.GET_NOTIFECTION_QOUNT_LOADING:
            return {
                data: state.data,
                loading: true,
            };
        case NotifectionTypes.GET_NOTIFECTION_QOUNT_SUCCESS:
            return {
                data: action.payload,
                loading: false,
            };
        case NotifectionTypes.GET_NOTIFECTION_QOUNT_ERROR:
            return {
                data: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

export {
    getNotifectionReducer,
    getNotifectionQountReducer
}

