import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { DashboardActionTypes } from "./constants";
import {
  acceptClientInvitationRequestApi,
  getAccountantClient,
  getClientContractListEndPoint,
  getClientInvitationRequestApi,
  getContractListEndPoint,
  getDocusignTokenEndPoint,
} from "./api";

function* getAccountantClientFunction({ payload }) {
  try {
    yield put({
      type: DashboardActionTypes.GET_ACCOUNTANT_DASHBOARD_LOADING,
      payload: {},
    });
    const response = yield call(getAccountantClient, payload);
    if (response.data.status) {
      yield put({
        type: DashboardActionTypes.GET_ACCOUNTANT_DASHBOARD_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: DashboardActionTypes.GET_ACCOUNTANT_DASHBOARD_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: DashboardActionTypes.GET_ACCOUNTANT_DASHBOARD_ERROR,
      payload: { message: error?.message },
    });
  }
}

function* getClientInvitationFunction(action) {
  try {
    yield put({
      type: DashboardActionTypes.GET_CLIENT_INVITATION_LIST_DASHBOARD_LOADING,
      payload: {},
    });
    const response = yield call(getClientInvitationRequestApi, action);
    // console.log(response, "responseresponse");
    if (response?.data?.status) {
      yield put({
        type: DashboardActionTypes.GET_CLIENT_INVITATION_LIST_DASHBOARD_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: DashboardActionTypes.GET_CLIENT_INVITATION_LIST_DASHBOARD_ERROR,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: DashboardActionTypes.GET_CLIENT_INVITATION_LIST_DASHBOARD_ERROR,
      payload: error?.response?.data || error,
    });
  }
}

function* acceptClientInviteFunction({ payload }) {
  try {
    yield put({
      type: DashboardActionTypes.ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_LOADING,
      payload: {},
    });
    const response = yield call(acceptClientInvitationRequestApi, { payload });
    if (response.data.status) {
      yield put({
        type: DashboardActionTypes.ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: DashboardActionTypes.ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: DashboardActionTypes.ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: DashboardActionTypes.ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_ERROR,
      payload: error?.data,
    });
  }
}

function* getDocusignTokenFunction(action) {
  try {
    yield put({
      type: DashboardActionTypes.GET_LOADING_DOCUSIGN_API_KEY,
      payload: {},
    });
    const response = yield call(getDocusignTokenEndPoint, action);
    if (response?.data?.status) {
      yield put({
        type: DashboardActionTypes.GET_SUCCESS_DOCUSIGN_API_KEY,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: DashboardActionTypes.GET_ERROR_DOCUSIGN_API_KEY,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: DashboardActionTypes.GET_ERROR_DOCUSIGN_API_KEY,
      payload: error?.response?.data || error,
    });
  }
}
function* getContractListFunction({ payload }) {
  try {
    // const accessToken = localStorage.getItem('token');
    // console.log(accessToken, "accessToken");

    yield put({
      type: DashboardActionTypes.GET_LOADING_CONTRACT_LIST,
      payload: {},
    });

    // Pass the token to the endpoint function
    const response = yield call(getContractListEndPoint, payload);

    if (response?.data?.status) {
      yield put({
        type: DashboardActionTypes.GET_SUCCESS_CONTRACT_LIST,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: DashboardActionTypes.GET_ERROR_CONTRACT_LIST,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: DashboardActionTypes.GET_ERROR_CONTRACT_LIST,
      payload: error?.response?.data || error,
    });
  }
}

function* getClientContractListFunction({ payload }) {
  try {
    yield put({
      type: DashboardActionTypes.GET_LOADING_CLIENT_CONTRACT_LIST,
      payload: {},
    });
    const response = yield call(getClientContractListEndPoint, { payload });
    if (response?.data?.status) {
      yield put({
        type: DashboardActionTypes.GET_SUCCESS_CLIENT_CONTRACT_LIST,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: DashboardActionTypes.GET_ERROR_CLIENT_CONTRACT_LIST,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: DashboardActionTypes.GET_ERROR_CLIENT_CONTRACT_LIST,
      payload: error?.response?.data || error,
    });
  }
}

export function* acctionGetAccountantClient(): any {
  yield takeEvery(
    DashboardActionTypes.GET_ACCOUNTANT_DASHBOARD_FIRST,
    getAccountantClientFunction
  );
}

export function* actionGetClientReqList(): any {
  yield takeEvery(
    DashboardActionTypes.GET_CLIENT_INVITATION_LIST_DASHBOARD_FIRST,
    getClientInvitationFunction
  );
}
export function* actionAcceptClientReqList(): any {
  yield takeEvery(
    DashboardActionTypes.ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_FIRST,
    acceptClientInviteFunction
  );
}
export function* actionGetDocusignToken(): any {
  yield takeEvery(
    DashboardActionTypes.GET_FIRST_DOCUSIGN_API_KEY,
    getDocusignTokenFunction
  );
}
export function* actionGetContractList(): any {
  yield takeEvery(
    DashboardActionTypes.GET_FIRST_CONTRACT_LIST,
    getContractListFunction
  );
}
export function* actionGetClientContractList(): any {
  yield takeEvery(
    DashboardActionTypes.GET_FIRST_CLIENT_CONTRACT_LIST,
    getClientContractListFunction
  );
}
function* accountantClientDetailSaga(): any {
  yield all([
    fork(acctionGetAccountantClient),
    fork(actionGetClientReqList),
    fork(actionAcceptClientReqList),
    fork(actionGetDocusignToken),
    fork(actionGetContractList),
    fork(actionGetClientContractList),
  ]);
}
export default accountantClientDetailSaga;
