// @flow
import { all } from "redux-saga/effects";

import authSaga from "./auth/saga";
import layoutSaga from "./layout/saga";
import businessEmailSaga from "./auth/businessEmail/saga";
import otpSendEmailSaga from "./auth/otpSendEmail/saga";
import verifyDetailSaga from "./auth/verifyDetail/saga";
import ProfileSaga from "../redux/accountPro/profile/saga";
import resetPasswordSaga from "./auth/resetPassword/saga";
import subAccountantSaga from "./subAccountant/saga";
import authComponentDataSendSaga from "./ComponentDataSend/authComponentDataSend/saga";
import organisationSaga from "./accountPro/organisation/saga";

// new code
import signupAccountSaga from "./auth/accountant/saga";
import accountDetailSaga from "./accountant/profile/acountDetail/saga";
import accountantSearchSaga from "./accountant/searchAccountant/saga";
import AnncouncementsSaga from "./accountant/announcements/saga";
import accountantClientDetailSaga from "./accountant/Dashboard/saga";
import getVatDetailsSaga from "./accountant/vatDetails/saga";
import AllRatingSaga from "./accountPro/AddRating/saga";
import AllNotifectionSaga from "./accountant/Notifection/saga";
import locationSaga from "./accountant/location/saga";

export default function* rootSaga(): any {
  yield all([
    authSaga(),
    layoutSaga(),
    businessEmailSaga(),
    otpSendEmailSaga(),
    verifyDetailSaga(),
    ProfileSaga(),
    resetPasswordSaga(),
    subAccountantSaga(),
    authComponentDataSendSaga(),
    signupAccountSaga(),
    accountDetailSaga(),
    organisationSaga(),
    accountantSearchSaga(),
    AnncouncementsSaga(),
    accountantClientDetailSaga(),
    getVatDetailsSaga(),
    AllRatingSaga(),
    AllNotifectionSaga(),
    locationSaga(),
  ]);
}
