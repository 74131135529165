import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { AccountSignupActionTypes } from "./constants";
import {
  getIndustoryEndPoint,
  getItaaEndPoint,
  getLanguagesApiEndPoint,
  getSkillEndPoint,
  signupAccountEndPoint,
} from "./api";

function* getSkillFunction() {
  try {
    yield put({
      type: AccountSignupActionTypes.GET_SKILL_LOADING,
      payload: {},
    });
    const response = yield call(getSkillEndPoint);
    if (response.data.status) {
      yield put({
        type: AccountSignupActionTypes.GET_SKILL_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: AccountSignupActionTypes.GET_SKILL_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: AccountSignupActionTypes.GET_SKILL_ERROR,
      payload: error?.data,
    });
    yield put({
      type: AccountSignupActionTypes.GET_SKILL_RESET,
      payload: {},
    });
  }
}

function* getItaaFunction(action) {
  try {
    yield put({
      type: AccountSignupActionTypes.GET_LOADING_ITAA,
      payload: {},
    });
    const response = yield call(getItaaEndPoint, action.payload.itaa);
    // if (response?.data?.status) {
    //     yield put({
    //         type: AccountSignupActionTypes.GET_SUCCESS_ITAA,
    //         payload: { ...response.data },
    //     });
    // } else {
    //     yield put({
    //         type: AccountSignupActionTypes.GET_ERROR_ITAA,
    //         payload: response.data,
    //     });
    // }
    if (response.data.status) {
      yield put({
        type: AccountSignupActionTypes.GET_SUCCESS_ITAA,
        payload: { ...response.data },
      });
      yield put({
        type: AccountSignupActionTypes.GET_RESET_ITAA,
        payload: {},
      });
    } else {
      yield put({
        type: AccountSignupActionTypes.GET_ERROR_ITAA,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: AccountSignupActionTypes.GET_ERROR_ITAA,
      payload: error?.response?.data || error,
    });
    yield put({
      type: AccountSignupActionTypes.GET_ERROR_ITAA,
      payload: {},
    });
  }
}

function* getIndustoryFunction(action) {
  try {
    yield put({
      type: AccountSignupActionTypes.GET_LOADING_INDUSTORY,
      payload: {},
    });
    const response = yield call(getIndustoryEndPoint, action.payload.itaa);
    if (response.data.status) {
      yield put({
        type: AccountSignupActionTypes.GET_SUCCESS_INDUSTORY,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: AccountSignupActionTypes.GET_ERROR_INDUSTORY,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: AccountSignupActionTypes.GET_ERROR_INDUSTORY,
      payload: error?.response?.data || error,
    });
  }
}
function* signupAccountantFunction(data) {
  try {
    yield put({
      type: AccountSignupActionTypes.SIGNUP_ACCOUNTANT_LOADING,
      payload: {},
    });
    const response = yield call(signupAccountEndPoint, data);
    // console.log(response, 'emailresp')
    if (response.data.status) {
      yield put({
        type: AccountSignupActionTypes.SIGNUP_ACCOUNTANT_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: AccountSignupActionTypes.SIGNUP_ACCOUNTANT_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: AccountSignupActionTypes.SIGNUP_ACCOUNTANT_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: AccountSignupActionTypes.SIGNUP_ACCOUNTANT_ERROR,
      payload: error?.data,
    });
    yield put({
      type: AccountSignupActionTypes.SIGNUP_ACCOUNTANT_RESET,
      payload: {},
    });
  }
}

function* getLanguageFunction() {
  try {
    yield put({
      type: AccountSignupActionTypes.GET_LANGUAGES_LOADING,
      payload: {},
    });
    const response = yield call(getLanguagesApiEndPoint);
    if (response.data.status) {
      yield put({
        type: AccountSignupActionTypes.GET_LANGUAGES_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: AccountSignupActionTypes.GET_LANGUAGES_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: AccountSignupActionTypes.GET_LANGUAGES_ERROR,
      payload: error?.data,
    });
    yield put({
      type: AccountSignupActionTypes.GET_LANGUAGES_RESET,
      payload: {},
    });
  }
}

// function* getLocationFunction() {
//   try {
//     yield put({
//       type: AccountSignupActionTypes.GET_LOCATION_LOADING,
//       payload: {},
//     });
//     const response = yield call(getLocationFromPostCodeApi);
//     if (response.data.status) {
//       yield put({
//         type: AccountSignupActionTypes.GET_LOCATION_SUCCESS,
//         payload: { ...response.data },
//       });
//     } else {
//       yield put({
//         type: AccountSignupActionTypes.GET_LOCATION_ERROR,
//         payload: { ...response.data },
//       });
//     }
//   } catch (error) {
//     yield put({
//       type: AccountSignupActionTypes.GET_LOCATION_ERROR,
//       payload: error?.data,
//     });
//     yield put({
//       type: AccountSignupActionTypes.GET_LOCATION_RESET,
//       payload: {},
//     });
//   }
// }

export function* acctionGetSkills(): any {
  yield takeEvery(AccountSignupActionTypes.GET_SKILL_FIRST, getSkillFunction);
}
export function* acctionGetItaa(): any {
  yield takeEvery(AccountSignupActionTypes.GET_FIRST_ITAA, getItaaFunction);
}
export function* acctionGetIndustory(): any {
  yield takeEvery(
    AccountSignupActionTypes.GET_FIRST_INDUSTORY,
    getIndustoryFunction
  );
}

export function* acctionSignupAccountant(): any {
  yield takeEvery(
    AccountSignupActionTypes.SIGNUP_ACCOUNTANT_FIRST,
    signupAccountantFunction
  );
}

export function* acctionLanguagesDetail(): any {
  yield takeEvery(AccountSignupActionTypes.GET_LANGUAGES, getLanguageFunction);
}

// export function* acctionLocationSaga(): any {
//   yield takeEvery(AccountSignupActionTypes.GET_LOCATION, getLocationFunction);
// }

function* signupAccountSaga(): any {
  yield all([
    fork(acctionGetSkills),
    fork(acctionSignupAccountant),
    fork(acctionGetItaa),
    fork(acctionGetIndustory),
    fork(acctionLanguagesDetail),
    // fork(acctionLocationSaga),
  ]);
}

export default signupAccountSaga;
