// @flow
import React from "react";
import Routes from "./routes/Routes";

// setup fake backend
import { configureFakeBackend } from "./helpers";

// Themes

// For Saas import Saas.scss
import "./assets/scss/Saas.scss";
import "./main.css";

// For Modern demo import Modern.scss
// import './assets/scss/Modern.scss';

// For Creative demo import Creative.scss
// import './assets/scss/Creative.scss';

// configure fake backend
configureFakeBackend();

type AppProps = {};

/**
 * Main app component
 */
const App = (props: AppProps): React$Element<any> => {
  return <Routes></Routes>;
};

export default App;
