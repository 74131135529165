export const NotifectionTypes = {

    GET_NOTIFECTION_FIRST: "@@auth/GET_NOTIFECTION_FIRST",
    GET_NOTIFECTION_LOADING: "@@auth/GET_NOTIFECTION_LOADING",
    GET_NOTIFECTION_SUCCESS: "@@auth/GET_NOTIFECTION_SUCCESS",
    GET_NOTIFECTION_ERROR: "@@auth/GET_NOTIFECTION_ERROR",
    GET_NOTIFECTION_RESET: "@@auth/GET_NOTIFECTION_RESET",

    GET_NOTIFECTION_QOUNT_FIRST: "@@auth/GET_NOTIFECTION_QOUNT_FIRST",
    GET_NOTIFECTION_QOUNT_LOADING: "@@auth/GET_NOTIFECTION_QOUNT_LOADING",
    GET_NOTIFECTION_QOUNT_SUCCESS: "@@auth/GET_NOTIFECTION_QOUNT_SUCCESS",
    GET_NOTIFECTION_QOUNT_ERROR: "@@auth/GET_NOTIFECTION_QOUNT_ERROR",
    GET_NOTIFECTION_QOUNT_RESET: "@@auth/GET_NOTIFECTION_QOUNT_RESET",


};
