import { getLocationEndPoint } from "./api";
import { getLocation } from "./constant";
import { all, fork, put, takeEvery, call } from "redux-saga/effects";

function* getLocationFunction(data) {
  try {
    yield put({
      type: getLocation.GET_LOCATION_LOADING,
      payload: {},
    });
    const response = yield call(getLocationEndPoint, data);
    if (response.data.features) {
      yield put({
        type: getLocation.GET_LOCATION_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: getLocation.GET_LOCATION_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: getLocation.GET_LOCATION_ERROR,
      payload: error?.data,
    });
    yield put({
      type: getLocation.GET_LOCATION_RESET,
      payload: {},
    });
  }
}

export function* watcherGetLocation(): any {
  yield takeEvery(getLocation.GET_LOCATION, getLocationFunction);
}

export default function* locationSaga(): any {
  yield all([fork(watcherGetLocation)]);
}
